<template>
  <div  class="ma-md-5 mb-16 px-0 " >
    <div class="d-flex">
      <v-btn icon small class="px-4 mt-6" to="/profile/menu" v-if="$vuetify.breakpoint.mobile">
        <v-icon size=20>mdi-arrow-left</v-icon>
      </v-btn>
      <div class="text-h6 font-weight-bold my-6">我的金库</div>
    </div>
    <div class="text-h6 item-header pa-2  my-6">我的推广二维码</div>
    <div><v-img :src="qrcode" max-width="120"></v-img></div>

    <div class="text-h6 item-header pa-2  my-6">佣金概览</div>
    

    <v-card class="d-flex pa-md-2" flat tile>
      <v-row>
        <v-col class="px-md-5" cols="12" md="4">
          <div class="text-h6 d-flex justify-space-between ma-2">
            <div>总佣金: </div>
            <div>{{mycommission.commission?mycommission.commission:"-"}}</div>
          </div>
        </v-col>
        <v-col class="px-md-5" cols="12" md="4">
          <div class="text-h6 d-flex justify-space-between ma-2">
            <div>可以提现佣金: </div>
            <div>{{mycommission.commission_cash?mycommission.commission_cash:"-"}}</div>
          </div>
        </v-col>
        <v-col class="px-md-5" cols="12" md="4">
          <div class="text-h6 d-flex justify-space-between ma-2">
            <div>已提现佣金: </div>
            <div>{{mycommission.commission_withdraw?mycommission.commission_withdraw:"-"}}</div>
          </div>
        </v-col>
      </v-row>

    </v-card>

    <v-card class="d-flex pa-md-2" flat tile>
      <v-row>
        <v-col class="" cols="12" md="4" v-if="mycommission.commission_cash>0">
          <v-btn  dark outlined  tile class="main-btn" @click="withdraw">提现</v-btn>
        </v-col>
        
      </v-row>
    </v-card>


  </div>
</template>

<script>

import { mapState } from 'vuex'
import userApi from '@/api/user'

export default {

  data: () => ({
    qrcode : "",
    mycommission : {}

  
    //
  }),
  computed: {
    ...mapState({
      apidomain: state => state.app.apidomain,
      domain: state => state.app.domain,
      imgdomain: state => state.app.imgdomain,
      uid: state => state.user.uid,
    }),
  },
  created() {
    

    userApi.commission().then(res => {
      if (this.uid>0) {
        this.mycommission = res.data
        let src = location.protocol+"//"+document.domain+"?source_uid="+this.uid
        this.qrcode = this.apidomain + "qrcode.php?code=" + encodeURIComponent(src)
        
      }
      
    })

    
  },
  methods: {
    homesearch() {
      alert(3)
    },
    withdraw() {
      this.$router.push("/profile/withdraw")
    }
    
  },
};
</script>
<style scoped>
.content {
  
  position: relative;
}  
.goods-item {
  cursor: pointer;
}

.item-header {
  background-color: black;
  color:#ffffff;
  
  width:100%;
}

</style>

